frappe.provide("frappe.webauthn"),
$.extend(frappe,{
    refresh_page: function() {
        window.location.reload()
    }}
)
frappe.webauthn={
    check_browser_supports_webauthn: function() {
        const { browserSupportsWebAuthn, browserSupportsWebAuthnAutofill } = SimpleWebAuthnBrowser;
        this.webauthnSupported = browserSupportsWebAuthn();
        if (!this.webauthnSupported)
            frappe.msgprint(__("Sorry, your current browser does not support the Webauthn protocol."))
    },
    start_registration: function() {
        frappe.webauthn.check_browser_supports_webauthn();
        const { startRegistration } = SimpleWebAuthnBrowser;
        frappe.call({
            method:"frappe.utils.webauthn_2fa.get_registration_options",
            type:"GET",
            args:{},
            freeze:true,
            success: async function(r){
                try{
                    console.log(r.message);
                    var regOptions = await startRegistration(r.message);
                    console.log(regOptions);
                    frappe.webauthn.query_device_nickname(regOptions);
                } catch(e){
                    console.log(e);
                }
            }
        })
    },
    query_device_nickname: function(e) {
        var d = new frappe.ui.Dialog({
            title:__("Setup your device"),
            fields:[{
                label:__("Nickname"),
                description:__("Provide a nickname for your registered security key."),
                fieldname:"nickname",
                fieldtype:"Data"
            }],
            primary_action_label:"Register key",
            primary_action: function(r) {
                frappe.webauthn.verify_registration_options(r.nickname,e);
                t.hide();
            }
        });
        d.show()
    },
    verify_registration_options: function(nickname,credential) {
        frappe.call({
            method:"frappe.utils.webauthn_2fa.register_webauthn_device",
            type:"POST",
            args:{
                "nickname":nickname,
                "credential":credential
            },
            freeze:true,
            success: function(e) {
                location.reload()
            }
        })
    },
    start_authentication: async function(e){
        const {startAuthentication} = SimpleWebAuthnBrowser;
        var t = SimpleWebAuthnBrowser.startAuthentication;
        return await startAuthentication(e,false)
    }
};
